import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

export default function ResetPasswordFooter() {
  const {t} = useTranslation();
  return (
    <div className="link-container mg-t-20">
      <p>
        {t("Already have an account ?")}&nbsp;
        <Link to="/account/signin" className="link">
          {t("Sign In")}
        </Link>
      </p>
    </div>
  );
}