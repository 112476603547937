import { ParkDomainModel } from "@src/dashboards/parks/core/models/park.domain-model";
import { RootState } from "@src/store";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";



export function useConsumption(dataField: ParkDomainModel.ConsumptionDataField) {
  // Memoized selectors
  const buildingsSelector = (state: RootState) => state.parkDashboard.selectedBuildings;
  const dataSelector = (state: RootState) => state.parkDashboard.consumption.data;
  const selectData = createSelector([buildingsSelector, dataSelector], (selectedBuildings, data) => {
    const buildingsIds = selectedBuildings?.map(b => b.id);
    return data?.filter(d => !!d.building.informations.surface && buildingsIds.includes(d.building.id)) ?? [];
  });
  const data = useSelector(selectData);

  const [maxPerPage, setMaxPerPage] = useState(7);
  const [buttonText, setButtonText] = useState("Show more");

  const handleShowMore = () => {
    if (buttonText === "Show more") {
      if (maxPerPage + 7 > data.length) {
        setButtonText("Show less");
        setMaxPerPage(data.length);
      } else {
        setMaxPerPage(maxPerPage + 7);
      }
    } else {
      if (maxPerPage - 7 <= 7) {
        setMaxPerPage(7);
        setButtonText("Show more");
      } else {
        setMaxPerPage(maxPerPage - 7);
      }
    }
  };

  const mapConsumptionData = (_data: ParkDomainModel.Consumption, maximumValue: number) => {
  
    const building = _data.building;
    const value = _data[dataField] / (building.informations?.surface || 1);
    const progressBarValue = _generateProgressBarValues(value, maximumValue);
  
    return {
      buildingId: _data.building.id,
      buildingName: _data.building.name,
      value: value.toFixed(2),
      progressBarColor: "primary",
      progressBarValue,
    };
  };

  data.sort((a, b) => {
    const surfaceA = a.building.informations?.surface || 1;
    const surfaceB = b.building.informations?.surface || 1;
    return Number(b[dataField]/ surfaceB) - Number(a[dataField] / surfaceA);
  });

  
  const dataSlice = data.slice(0, maxPerPage);
  let maximumValue: number;
  if (dataSlice.length === 0) {
    maximumValue = 1;
  } else {
    maximumValue = data[0][dataField] / (data[0].building.informations?.surface || 1);
  }
  const consumptionData = dataSlice.map((datum) => mapConsumptionData(datum, maximumValue));

  const sum = dataSlice.reduce((total, datum) => {
    const value = datum[dataField] / (datum.building.informations?.surface || 1);
    return total + value;
  }, 0);
  const medianIndex = Math.floor(dataSlice.length / 2);

  let median;
  let average;
  if (dataSlice.length === 0) {
    median = 0;
    average = 0;
  } else {
    average = sum / dataSlice.length;
    median = dataSlice[medianIndex][dataField] / (dataSlice[medianIndex].building.informations?.surface || 1);
  }

  const operations = [{value: average, label: "Average"}, {value: median, label: "Median"}];
  const consumptionDataSecondary = operations.map((operation) => {
    const progressBarValue = _generateProgressBarValues(operation.value, maximumValue);
    return {
      buildingId: _generateRandomId(),
      buildingName: operation.label,
      value: operation.value.toFixed(2),
      progressBarColor: "secondary",
      progressBarValue,
    };
  });
  consumptionData.push(...consumptionDataSecondary);

  return {
    handleShowMore,
    consumptionData,
    buttonText,
  };
}

function _generateProgressBarValues(value: number, maximumValue: number) {
  const maxProgressBarValue = Math.ceil((value / maximumValue) * 90);
  const progressBarValue = maxProgressBarValue - (maxProgressBarValue % 5);
  return progressBarValue;
}

function _generateRandomId() {
  return Math.floor(Math.random() * 1_000_000);
}
