import {useTranslation} from "react-i18next";
import {useResetPasswordForm} from "@src/auth/react/sections/form/use-reset-password";
import PasswordInfo from "@src/auth/react/sections/PasswordInfo";
import { Button } from "@mui/material";

export default function ResetPasswordForm() {
  const { t } = useTranslation();
  const presenter = useResetPasswordForm();

  return (
    <form onSubmit={presenter.onSubmit}>
      <div className="form-group text-left">
        <label>{t("New Password")}</label>
        <input
          {...presenter.register("password", presenter.passwordOption)}
          className="form-control"
          placeholder={t("Enter your password")}
          name="password"
          id="password"
          type="password"
        />
      </div>
      <div className="form-group text-left">
        <label>{t("Confirm Password")}</label>
        <input
          {...presenter.register("password_confirmation", presenter.passwordConfirmationOption)}
          className="form-control"
          placeholder={t("Enter your password")}
          name="password_confirmation"
          id="password_confirmation"
          type="password"
        />
      </div>
      <Button variant="contained" color="primary" type="submit" sx={{width: "100%"}} disabled={presenter.isSubmittable}>
        {t("Reset Password")}
      </Button>
      <br/>
      <div className="card text-center mt-3">
        <PasswordInfo />
      </div>
    </form>
  );
}