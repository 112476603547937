import Loading from "@src/common/components/is-loading";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  CartesianGrid,
  Legend,
  Line,
  BarChart as RBarChart,
  LineChart as RLineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { Data, Mode } from "@src/common";
import { RootState } from "@src/store";
import PeriodButtons from "./buttons-period";
import DropdownDownload from "./dropdown-download";
import { HeaderChart } from "./header-chart";
import {Moment} from "moment";
import { remoteTemperatureDeveui } from "../../index";
import {useTranslation} from "react-i18next";
import { Button } from "@mui/material";

export function LineChart({data, XKey, YKey, lines, chartId, eng, ...props}: Props) {
  const {t} = useTranslation("metrology");
  const [X, setX] = useState<any[]>([]);
  const [ticks, setTicks] = useState<number[]>([]);
  const [Y, setY] = useState<any[]>([]);
  const [weekends, setWeekends] = useState<Point[]>([]);
  const [left, setLeft] = useState<any>();
  const [right, setRight] = useState<any>();
  const mode = useSelector((state: RootState) => state.metrology.mode);
  const startPeriod = useSelector((state: RootState) => state.metrology.start);
  const endPeriod = useSelector((state: RootState) => state.metrology.end);
  const period = useSelector((state: RootState) => state.metrology.displayFormat);
  const dataIsLoading = useSelector((state: RootState) => state.metrology.dataIsLoading);
  const headerIsLoading = useSelector((state: RootState) => state.metrology.headerIsLoading);
  let timezone: string;
  if (data !== undefined && data.length != 0) {
    timezone = data[0].timezone;
  }

  function fillWeekends(startTimestamp: number, endTimestamp: number): Point[] {
    const startDate = moment.unix(startTimestamp);
    const nextDate = moment.unix(startTimestamp);
    const endDate = moment.unix(endTimestamp);
    const weekendPoints: Point[] = [];
    const SUNDAY = 0;
    const SATURDAY = 6;

    let previousDate: null | Moment;
    for (let i = 0; startDate < endDate; i++) {
      nextDate.add(1, "days");
      const weekDay = startDate.days();
      if (weekDay === SATURDAY || weekDay === SUNDAY) {
        let x1Date;
        let x2Date;
        if (period[eng] === "day") {
          if (previousDate && previousDate.days() === SATURDAY) {
            previousDate = startDate.clone();
            startDate.add(1, "days");
            continue;
          }
          x1Date = startDate.unix();
          x2Date = nextDate.unix();
        } else {
          x1Date = startDate.startOf("days").unix();
          x2Date = startDate.endOf("days").unix();
        }
        weekendPoints.push({
          x1: x1Date,
          x2: x2Date
        });
      }
      previousDate = startDate.clone();
      startDate.add(1, "days");
    }
    return weekendPoints;
  }

  /* TODO: Remove this condition when insight no longer uses the tecice DB
        to store sensor data and the data is in UTC format.
    */
  function isLastSundayOfMarch(date: Moment) {
    const dateToCheck = date.clone();
    if (dateToCheck.month() === 2 && dateToCheck.day() === 0) {
      const lastDayOfMarch = dateToCheck.clone().endOf("month");
      return dateToCheck.date() > lastDayOfMarch.date() - 7;
    }
    return false;
  }


  function fillXAxisDates(): any[] {
    const startDate = moment(props.startDate ? props.startDate : startPeriod);
    const endDate = moment(props.endDate ? props.endDate : endPeriod);
    const currentDate = moment(startDate);

    const diffDays = Math.abs(endDate.diff(startDate, "days"));
    const dates = [];
    if (diffDays < 2) {
      while (currentDate.isSameOrBefore(endDate)) {
        const newDate = currentDate.clone();
        newDate.set({minute: 0});
        if (newDate.hour() % 3 === 0 || currentDate.isSame(startDate) || currentDate.isSame(endDate)) {
          dates.push(newDate.unix());
        }
        currentDate.add(1, "hours");
      }
      return dates;
    }
    while (currentDate.isSameOrBefore(endDate)) {
      const midnightDate = currentDate.clone().startOf("day");
      dates.push(midnightDate.unix());
      if (diffDays <= 7 && period[eng] !== "day") {
        const hoursToAdd = isLastSundayOfMarch(currentDate) ? 11 : 12;
        const middayDate = currentDate.clone().startOf("day").add(hoursToAdd, "hours");
        dates.push(middayDate.unix());
      }
      currentDate.add(1, "days");
    }
    if (endDate.hour() >= 12) {
      const midnightDate = currentDate.clone().startOf("day");
      dates.push(midnightDate.unix());
    }
    return dates;
  }

  useEffect(() => {
    if (data?.length) {
      const xDates = fillXAxisDates();
      if (xDates.length >= 2) {
        setX([xDates[0], xDates[xDates.length - 1]]);
        const weekendTimestamp = fillWeekends(xDates[0], xDates[xDates.length - 1]);
        setWeekends(weekendTimestamp);
      }
      setTicks(xDates);
    }
  }, [data]);

  const zoom = () => {
    const dataFrom = data.findIndex(d => d[XKey] === left);
    const dataTo = data.findIndex(d => d[XKey] === right);
    const slice = data.slice(dataFrom, dataTo);
    const inversed = !(left < right);
    setX(!inversed ? [left, right] : [right, left]);
    setY([0, slice.reduce((prev, s) => {
      lines.forEach(k => {
        if (k !== XKey) {
          if (s[k] > 0 && s[k] > prev) {
            prev = s[k];
          }
        }
      });
      return prev;
    }, 0)]);
    setLeft(null);
    setRight(null);
  };

  const resetZoom = () => {
    setX([]);
    setY([]);
    setLeft(null);
    setRight(null);
  };

  function renderText(child: string, x: number, y: number, rotate: string | 0, stroke: any, key?: any) {
    return (<text
      key={key}
      x={x}
      y={y}
      transform={`rotate(${rotate})`}
      textAnchor="middle"
      stroke={stroke}>{child}</text>);
  }

  function AxisLabel({axisType, axisOrientation, x, y, width, height, stroke, children}: any) {
    const isVert = axisType === "yAxis";
    const isRight = axisOrientation === "right";

    let cx = isVert ? x : x + (width / 2);
    const cy = isVert ? (height / 2) + y : y + height + 20;

    const degrees = isRight ? 90 : 270;
    if (isRight) cx += 70;

    const rot = isVert ? `${degrees} ${cx} ${cy}` : 0;
    const lineHeight = 20;

    if (children.length > 1 && children.map) {
      return (<g>
        {children.map((child: string, index: number) =>
          renderText(
            child,
            cx,
            cy + index * lineHeight,
            rot,
            stroke,
            index)
        )}
      </g>);
    }

    return renderText(children, cx, cy, rot, stroke);
  }

  function setDateTick(timestamp: number, dataSize?: number) {
    const lang = document.documentElement.lang;
    const format = dataSize >= 15 ? (lang === "fr" ? "DD/MM" : "MM-DD") : "L";
    return moment.unix(timestamp).startOf("days").locale(lang).format(format);
  }

  if (!data && !dataIsLoading[eng]) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <h2>{t("No data available for this graph")}</h2>
      </div>
    );
  }
  return (
    <div className="col">
      {
        props.withPeriod && (
          <div className="text-center">
            <PeriodButtons eng={eng}/>
          </div>
        )
      }
      <div className="col d-flex justify-content-between">
        <div>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={resetZoom}
              sx={{
                '&:hover': {
                  variant: 'contained',
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText'
                }
              }}
            >
              {t("Reset zoom")}
            </Button>
        </div>
        <DropdownDownload HTMLElementId={chartId} chartType="line"/>
      </div>
      <div className="chart-container" id={chartId}>
        <div className={"mb-3 mx-5 d-flex justify-content-center"}>
          {
            headerIsLoading[eng] ?
              <Loading text={t("Loading header in pogress ...")}/>
              :
              <HeaderChart header={props.header} chartId={chartId}/>
          }
        </div>
        <div className="col py-0 my-0">
          {
            dataIsLoading[eng] ?
              <Loading text={t("Loading chart in progress ...")}/>
              :
              (
                <>
                  <ResponsiveContainer width={"100%"} height={350}>
                    {
                      mode === Mode.COMFORT || ["min", "hour"].includes(period[eng]) ? (
                        <RLineChart width={600} height={300} data={data}
                          onMouseDown={(event) => setLeft(event?.activeLabel)}
                          onMouseMove={(event) => left && setRight(event.activeLabel)}
                          onMouseUp={zoom}
                          margin={{
                            top: 20,
                            right: 0,
                            left: 20,
                            bottom: 5,
                          }}>
                          <CartesianGrid/>
                          <   XAxis label={props.xAxisUnit && (({viewBox}: any) => <AxisLabel
                            axisType={"xAxis"} {...viewBox} >{t(props.xAxisUnit)}</AxisLabel>)}
                          dataKey={XKey || "date"}
                          tickFormatter={(value) => {
                            let date = moment.unix(value);
                            if (timezone) {
                              date = date.tz(timezone);
                            }
                            const hours = date.format("HH:mm");

                            if (X.length >= 2) {
                              const hour = +hours.split(":")[0];
                              if (hour % 3 && value != X[0] && value != X[1]) {
                                return hours;
                              }
                            }
                            if (hours === "12:00") {
                              return hours;
                            }
                            return date.locale(document.documentElement.lang).format("DD MMM");
                          }}
                          allowDataOverflow allowDuplicatedCategory={false} type="number" domain={X}
                          ticks={ticks}/>
                          <YAxis yAxisId="left" allowDecimals={false} type='number'
                            domain={props.domain || ["auto", "auto"]}
                            label={props.yAxisUnit && (({viewBox}: any) => <AxisLabel
                              axisType='yAxis' {...viewBox} >{t(props.yAxisUnit)}</AxisLabel>)}/>
                          {

                          }
                          <YAxis yAxisId="right" orientation="right" allowDecimals={false} type='number'
                            domain={props.domain || ["auto", "auto"]}
                            label={props.yAxisUnitBis && (({viewBox}: any) => <AxisLabel
                              axisType='yAxis'
                              axisOrientation="right" {...viewBox} >{t(props.yAxisUnitBis)}</AxisLabel>)}/>
                          <Tooltip
                            labelFormatter={(label, payload) => {
                              const date = payload[0]?.payload[XKey];
                              if (date) {
                                let convertedDate = moment.unix(date);
                                if (timezone) {
                                  convertedDate = convertedDate.tz(timezone);
                                }
                                return convertedDate.locale(document.documentElement.lang).format("LLL");
                              }
                            }}
                            formatter={(payload, _, context) => {
                              const regex = /\(([^)]+)\)/;
                              const regex_deveui = /climespace_vol/i;
                              const match = props.yAxisUnit?.match(regex);
                              const label = props.lookupLabels[context.payload.deveui];

                              if (eng === "thermostat") {
                                return ([`${payload ? "ON" : "OFF"}`, label]);
                              }
                              if (!match) {
                                return [payload, label];
                              }
                              let unit = match ? match[1] : "";
                              if (eng === "capt" && label.toLowerCase().includes("co2")) {
                                unit = "ppm";
                              } else if (label == t("Outdoor temperature")) {
                                unit = "°C";
                              } else if (regex_deveui.test(context.payload.deveui) && eng === "cold") {
                                unit = "\u33A5";
                              }
                              return ([`${payload} ${unit}`, label]);
                            }}
                          />
                          {
                            weekends.map(weekend => {
                              return <ReferenceArea
                                fill="#228b22"
                                fillOpacity={0.2}
                                key={weekend.x1 + weekend.x2}
                                x1={weekend.x1}
                                x2={weekend.x2}
                                yAxisId="left"
                              />;
                            })
                          }
                          {props.legend && <Legend/>}
                          {Object.keys(data).length && data?.map(s => {
                            const regex = /climespace_vol/i;
                            let position = "left";
                            if (regex.test(s.deveui) && eng === "cold") {
                              position = "right";
                            } else if (s.label.toLowerCase().includes("co2") && eng === "capt") {
                              position = "right";
                            }
                            return s.data &&
                                            <Line data={s.data} connectNulls dot={false} type={"monotone"} stroke={s.color}
                                              strokeDasharray={s.deveui == remoteTemperatureDeveui ? "3 3" : ""}
                                              key={"chart-line-" + s.deveui}
                                              dataKey={YKey || "value"}
                                              yAxisId={position}/>;
                          })}
                          {
                            (right && left) &&
                                          <ReferenceArea
                                            x1={left}
                                            x2={right}
                                            yAxisId="left"
                                            strokeOpacity={0.3}
                                          />
                          }
                          {props.children}
                        </RLineChart>) :
                        (
                          <RBarChart
                            width={600}
                            height={300}
                            data={data}
                            onMouseDown={(event) => setLeft(event.activeLabel)}
                            onMouseMove={(event) => left && setRight(event.activeLabel)}
                            margin={{
                              top: 20,
                              right: 0,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey={XKey || "date"} allowDuplicatedCategory={false}
                              tickFormatter={(timestamp) => setDateTick(timestamp, data.length)}/>
                            <YAxis yAxisId="bar-left" label={
                              props.yAxisBarChartTitle && (({viewBox}: any) => <AxisLabel
                                axisType={"yAxis"} {...viewBox} >{props.yAxisBarChartTitle}</AxisLabel>)
                            }/>
                            <YAxis
                              yAxisId="bar-right"
                              orientation="right"
                              label={props.yAxisBarChartTitleBis && (({viewBox}: any) => <AxisLabel
                                axisType={"yAxis"}
                                axisOrientation="right" {...viewBox} >{props.yAxisBarChartTitleBis}</AxisLabel>)
                              }/>
                            <Tooltip
                              formatter={(value, _, context) => {
                                const regex = /\(([^)]+)\)/;
                                const regex_deveui = /climespace_vol/i;
                                const match = props.yAxisUnit.match(regex);
                                let unit = match ? match[1] : "";
                                if (eng !== "capt") {
                                  unit += "h";
                                  if (regex_deveui.test(context.payload.deveui) && eng === "cold") {
                                    unit = "m\u00b3";
                                  }
                                }
                                return ([
                                  `${Number(value).toFixed(2)} ${unit}`,
                                  props.lookupLabels[context.payload.label]
                                ]);
                              }}
                              labelFormatter={(label, payload) => {
                                const date = payload[0]?.payload[XKey];
                                if (date) {
                                  let convertedDate = moment.unix(date);
                                  if (timezone) {
                                    convertedDate = convertedDate.tz(timezone);
                                  }
                                  return convertedDate.locale(document.documentElement.lang).format("LL");
                                }
                              }}/>
                            {
                              weekends.map(weekend => {
                                return <ReferenceArea
                                  fill="#228b22"
                                  fillOpacity={0.2}
                                  key={weekend.x1 + weekend.x2}
                                  x1={weekend.x1}
                                  x2={weekend.x2}
                                />;
                              })
                            }
                            {props.legend && <Legend/>}
                            {Object.keys(data).length && data?.map(s => {
                              const regex = /climespace_vol/i;
                              let position = "bar-left";
                              if (regex.test(s.deveui) && eng === "cold") {
                                position = "bar-right";
                              } else if (s.label.toLowerCase().includes("co2") && eng === "capt") {
                                position = "bar-right";
                              }
                              return <Bar data={s.data} type={"monotone"} fill={s.color} stroke={s.color}
                                key={s.label}
                                dataKey={YKey || "value"}
                                yAxisId={position}
                              />;
                            })}
                          </RBarChart>
                        )
                    }
                  </ResponsiveContainer>
                  <div className="col d-flex justify-content-center">
                    {props.areaLegend}
                  </div>
                </>
              )
          }
        </div>
      </div>
    </div>
  );
}

interface Props {
    eng?: "hot" | "cold" | "elec" | "capt" | "water" | "gas" | "thermostat";
    lookupLabels?: any;
    data: Data[];
    XKey: string;
    YKey?: string;
    headerChart?: any;
    chartId?: string;
    lines?: any[];
    header?: any;
    children?: any;
    legend?: boolean;
    areaLegend?: any;
    optimizations?: boolean;
    yAxisUnit?: string;
    yAxisUnitBis?: string;
    yAxisBarChartTitle?: string;
    yAxisBarChartTitleBis?: string;
    xAxisUnit?: string;
    ticks?: number[];
    minTickGap?: number;
    domain?: any[];
    withPeriod?: boolean;
    updatePeriod?: (value: string) => void;
    timezone?: string;
    startDate?: string;
    endDate?: string;
}

interface Point {
    x1: number;
    x2: number;
}
