import {Dependencies} from "@src/app/dependencies";
import {AppStore, createStore} from "@src/store";
import {AxiosGateway} from "@src/core/adapters/system.http-gateway";
import { SystemDateProvider } from "@src/core/adapters/system.date-provider";
import { createTheme, Theme } from "@mui/material/styles";
import { accentaClient } from "./clients-theme/accenta";
import { esmeClient } from "./clients-theme/esme";

export class App {
  public dependencies: Dependencies;
  public store: AppStore;
  public theme: Theme;

  constructor() {
    this.dependencies = this.setupDependencies();
    this.store = createStore({dependencies: this.dependencies});
    const palette = this.setupPalette();
    this.theme = createTheme(palette);
  }

  setupDependencies(): Dependencies {
    return {
      httpGateway: new AxiosGateway(),
      dateProvider: new SystemDateProvider()
    };
  }

  setupPalette() {
    const client = process.env.REACT_APP_CLIENT;
    const theme = {
      palette: {
        primary: {
          main: "#1976d2",
        },
        secondary: {
          main: "#ce93d8",
        },
      },
    };

    switch (client) {
    case "accenta":
      theme.palette = {...theme.palette, ...accentaClient.palette};
      break;
    case "esme":
      theme.palette = {...theme.palette, ...esmeClient.palette};
      break;
    }

    return theme;
  }
}

export const app = new App();