import {Provider} from "react-redux";
import {app} from "@src/app/main";
import {DependenciesProvider} from "@src/app/react/DependenciesProvider";
import {BrowserRouter} from "react-router-dom";
import Router from "@src/routes";
import { ThemeProvider } from "@mui/material/styles";


export function AppWrapper() {
    const root = document.documentElement;
    root.style.setProperty("--primary", app.theme.palette.primary.main);
    root.style.setProperty("--primary-dark", app.theme.palette.primary.dark);
    root.style.setProperty("--primary-light", app.theme.palette.primary.light);
    root.style.setProperty("--secondary", app.theme.palette.secondary.main);

  return (
    <Provider store={app.store}>
      <DependenciesProvider dependencies={app.dependencies}>
        <ThemeProvider theme={app.theme}>
          <BrowserRouter>
            <Router/>
          </BrowserRouter>
        </ThemeProvider>
      </DependenciesProvider>
    </Provider>
  );
}
