import {useDashboardParkPage} from "@src/dashboards/parks/react/pages/use-dashboard-park-page";
import {YearSelector} from "@src/dashboards/parks/react/sections/year-selector/YearSelector";
import {TotalIndicators} from "@src/dashboards/parks/react/sections/indicators/TotalIndicators";
import "./index.scss";
import DetailCard from "@src/dashboards/parks/react/sections/details/DetailCard";
import BuildingFilter from "@src/dashboards/parks/react/sections/filters/BuildingFilter";
import {MapOfBuildings} from "@src/dashboards/parks/react/sections/maps/MapOfBuildings";
import {BuildingDistribution} from "@src/dashboards/parks/react/sections/distributions/BuildingDistribution";
import {BuildingClassification} from "@src/dashboards/parks/react/sections/classifications/BuildingClassification";
import { BuildingSynthesis } from "../sections/synthesis/BuildingSynthesis";
import Loading from "@src/common/components/is-loading";

export function DashboardParcPage() { 
  const presenter = useDashboardParkPage();
  const { t } = presenter;

  return (
    <div id="container-projects">
      <div className="row row-sm mb-3">
        <YearSelector
          selectedYear={presenter.selectedYear} 
          setYear={presenter.setSelectedYear}
        />
      </div>
      <div>
        { presenter.consumptionStatus === "pending" && <Loading /> }
      </div>
      <div className="row row-sm">
        <TotalIndicators />
      </div>
      <div className="row row-sm">
        <div className="col col-md-6 park-card">
          <DetailCard />
        </div>
        <div className="col col-md-6" style={{marginBottom: "20px"}}>
          <div className="card h-100"
            style={{maxHeight: "375px", minHeight: "375px"}}>
            <div className="card-body">
              <div className="main-content-label text-center filter-title">
                {t("Filters")}
              </div>
              <div className="content-filter row">
                <BuildingFilter />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-5" id="map">
          <MapOfBuildings />
        </div>
        <div className="col-7">
          <div className="card mg-b-20 mg-md-b-0">
            <div className="card-body">
              <BuildingDistribution />
            </div>
          </div>
        </div>
      </div>
      <div className="row row-sm">
        <BuildingClassification />
      </div>
      <div className="row row-sm">
        <div className="col">
          <BuildingSynthesis />
        </div>
      </div>
    </div>
  );
}