export default {
  "Filters": "Filtres",
  "Periods": "Périodes",
  "Measurement points": "Points de mesure",
  "Outdoor temperature": "Température extérieure",
  "Room Type": "Type de salle",
  "Select": "Sélectionner",
  "Floors": "Étages",
  "Zones": "Zones",
  "Tenants": "Loacataires",
  "Select all": "Sélectionner tout",
  "Unselect all": "Déselectionner tout",
  "Electricity": "Electricité",
  "Heating Network": "Réseau de chauffage",
  "Cooling Network": "Réseau de froid",
  "HVAC Systems Sensors": "Capteurs de systèmes CVC",
  "Delivery Point": "Point de livraison",
  "Sub meter": "Sous-Compteur",
  "Sensors": "Capteurs",
  "No data available for this graph": "Aucune donnée disponible pour ce graphique",
  "Loading header in pogress ...": "Chargement de l'en-tête en cours ...",
  "Loading chart in progress ...": "Chargement du graphique en cours ...",
  "Day": "Jour",
  "Month": "Mois",
  "Air quality": "Qualité d'air",
  "Winter": "Hiver",
  "Summer": "Eté",
  "Yearly": "Annuel",
  "Period": "Période",
  "Min": "Min",
  "Max": "Max",
  "Activate": "Activer",
  "Thresholds": "Seuils",
  "Temperature": "Température",
  "Humidity": "Humidité",
  "CO2 Rate": "Taux de CO2",
  "Luminosity": "Luminosité",
  "Save": "Enregistrer",
  "Not Categorized": "Sans catégorie",
  "Room type": "Type de salle",
  "No options available": "Aucune option disponible",
  "Reset zoom": "Réinitialiser le zoom",
  "Consumption distribution according to Building Occupancy": "Répartition de la consommation en fonction de l'occupation",
  "Consumption distribution according to Peak and Off Peak Hours": "Répartition de la consommation par tranche horosaisonnière",
  "Volume distribution according to building occupancy": "Répartition du volume en fonction de l'occupation",
  "Electricity power (kW)": "Puissance électrique (kW)",
  "Heating power (kW)": "Puissance de chauffage (kW)",
  "Cooling power (kW)": "Puissance de refroidissement (kW)",
  "Temperature (°C)": "Température (°C)",
  "Water Consumption": "Consommation d'eau",
  "Water consumption": "Consommation d'eau",
  "Gas Consumption": "Consommation de gaz",
  "Gas consumption": "Consommation de gaz",
  "Thermostat status [I/O]": "Statut du thermostat [I/O]",
  "Electricity consumption": "Consommation électrique",
  "Heating consumption (kWh)": "Réseau de chaud - Consommation (kWh)",
  "Cooling consumption (kWh)": "Réseau de froid - Consommation (kWh)",
  "Volume": "Volume",
  "Co2 concentration (ppm)": "Concentration du Co2 (ppm)",
};
