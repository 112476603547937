import { Link } from "react-router-dom";
import HeaderSignIn from "@src/auth/react/sections/header/HeaderSignIn";
import SignInForm from "@src/auth/react/sections/form/SignInForm";
import Layout from "@src/auth/react/sections/Layout";
import {useTranslation} from "react-i18next";
import "../styles/shared.scss";

export function SigninPage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="card-sigin">
        <HeaderSignIn/>
        <div className="card-sigin">
          <div className="main-signup-header">
            <SignInForm/>
            <div className="mt-3" >
              <Link className="link" to={"/account/password/forgot"}>
                {t("Forgot your password ?")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
} 