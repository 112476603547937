import { esmeClient } from "@src/auth/clients/esme";
import { accentaClient } from "@src/auth/clients/accenta";
import { Client } from "@src/auth/clients/types";

export function useLayoutPresenter() {
  let client: Client;

  switch (process.env.REACT_APP_CLIENT) {
  case "esme":
    client = esmeClient;
    break;
  case "accenta":
    client = accentaClient;
    break;
  default:
    client = accentaClient;
    break;
  }

  return {
    client,
  };
}
