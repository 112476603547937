import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import { format, setShouldFetchSensors } from "@src/store/reducers/metrology";
import { RootState } from "@src/store";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

interface Props {
  eng: "hot"|"elec"|"cold"|"capt"|"water"|"gas"|"thermostat"
}
export default function PeriodButtons({eng}: Props) {
  const {t, i18n} = useTranslation("metrology");
  const period = useSelector((state: RootState) => state.metrology.displayFormat);
  const dispatch = useDispatch();

  const handlePeriod = (_: any, newPeriod: string | null) => {
    dispatch(setShouldFetchSensors(true));
    dispatch(format({eng, period: newPeriod ?? "min"}));
  };

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  return (
    <ToggleButtonGroup
      value={period[eng]}
      exclusive
      onChange={handlePeriod}
      aria-label="Select a period"
    >
      <ToggleButton value="min" aria-label="10 min" style={{minWidth: "90px"}}>
        10 min
      </ToggleButton>
      {
        eng !== "thermostat" && (
          <>
            <ToggleButton value="hour" aria-label="1 hour" style={{minWidth: "90px"}}>
            1h
            </ToggleButton>
            <ToggleButton value="day" aria-label="1 day" style={{minWidth: "90px"}}>
              {t("Day")}
            </ToggleButton>
            <ToggleButton value="month" aria-label="1 month" style={{minWidth: "90px"}}>
              {t("Month")}
            </ToggleButton>
          </>
        )
      }
    </ToggleButtonGroup>
  );
}