import { Button } from "@mui/material";
import {useSignInForm} from "@src/auth/react/sections/form/use-sign-in-form";
import LoginError from "@src/auth/react/sections/LoginError";

function SignInForm() {
  const presenter = useSignInForm();
  const {t} = presenter;

  return (
    <>
      <LoginError statusCode={presenter.requestStatusCode}/>
      <form onSubmit={presenter.onSubmit}>
        <div className="form-group">
          <label htmlFor="email">{t("Email")}</label>
          <input
            {...presenter.register("email", presenter.emailOptions)}
            className="form-control"
            placeholder={t("Enter your email")}
            name="email"
            id="email"
            type="text"
          />
          {
            presenter.errors.email && (
              <p className="text-danger">
                {presenter.errors.email.message}
              </p>)
          }
        </div>
        <div className="form-group">
          <label htmlFor="password">{t("Password")}</label>
          <input
            {...presenter.register("password", presenter.passwordOptions)}
            className="form-control"
            placeholder={t("Enter your password")}
            name="password"
            id="password"
            type="password"
          />
          {
            presenter.errors.password && (
              <p className="text-danger">
                {presenter.errors.password.message}
              </p>
            )
          }
        </div>

        <Button variant="contained" color="primary" type="submit" sx={{width: "100%"}} disabled={presenter.isNotSubmittable}>
          {
            presenter.requestStatus == "pending" ?
              (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">{t("Loading ...")}</span>
                </div>
              ) :
              <>{t("Sign In")}</>
          }
        </Button>
      </form>
    </>
  );
}

export default SignInForm;