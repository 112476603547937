
import { Button, Stack } from "@mui/material";
import { useEnergyConsumptionPresenter } from "./use-energy-consumption-presenter";
import LinearProgress from "@mui/material/LinearProgress";
  

export function EnergyConsumption() {
  const presenter = useEnergyConsumptionPresenter();
  const {t} = presenter;

  return (
    <>
      <div className="card">
        <div className="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0 text-center">
          <div className="d-flex justify-content-center">
            <h4 className="card-title mg-b-10">
              {t("Energy Consumption Ranking")}
              <br />
              <small className="text-muted">
                {t("kWh EF/m²/year")}
              </small>
            </h4>
          </div>
        </div>
        <div className="card-body text-center">
          <ul className="sales-session mb-0">
            {
              presenter.consumptionData.map((data) => (
                <li key={data.buildingId}>
                  <div>
                    <div className="d-flex justify-content-between">
                      <h6>{data.buildingName}</h6>
                      <p className="fw-semibold mb-2">
                        {data.value}
                      </p>
                    </div>
                    <Stack spacing={2} sx={{ flexGrow: 1 }}>
                      <LinearProgress 
                        variant="determinate" 
                        color={data.progressBarColor as ("primary" | "secondary")}
                        value={data.progressBarValue} />
                    </Stack>
                  </div>
                </li>
              ))}
          </ul>
          <Button variant="contained" size="small" color="primary" onClick={presenter.handleShowMore}>
            { t(presenter.buttonText)}
          </Button>
        </div>
      </div>
    </>
  );
}
