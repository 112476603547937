import { useBuildingDistributionPresenter } from "./use-building-distribution-presenter";
import PieChart from "@src/common/components/pie-chart";

export function BuildingDistribution() {
  const presenter = useBuildingDistributionPresenter();
  const {t} = presenter;

  return (
    <>
      <div className="card-header pb-0">
        <h3 className="card-title mb-2"
          style={{textAlign: "center"}}>
          {t("Building Energy Information")}
        </h3>
      </div>
      <div className="content-pie-energies ht-300" id="buildingsPieCharts">
        <div className="chart-group">
          <p className="text-center">{t("Network")}</p>
          <PieChart data={presenter?.networksDoughnut} />
        </div>
        <div className="chart-group">
          <p className="text-center">{t("EPD")}</p>
          <PieChart data={presenter?.dpeDoughnut} />
        </div>
        <div className="chart-group">
          <p className="text-center">{t("GHG")}</p>
          <PieChart data={presenter?.gesDoughnut} />
        </div>
      </div>
    </>
  );
}

