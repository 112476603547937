import { Client } from "./types";

export const esmeClient: Client = {
  name: "esme",
  logo: process.env.STATIC_URL + "img/brand/esme/esme_logo.svg",
  slogan: "",
  style: {
    layoutBackground: "layout-secondary",
  }
};
