import { ListOfDetailledSensors, ListOfSensorsNames } from "@src/common";
import {
  resetDataBySensor,
  setDataIsLoadingByType,
  setDateIsChanging,
  setHeaderByType,
  setHeaderIsLoadingByType,
  switchSensorDisplay,
  updateDataBySensor
} from "@src/store/reducers/metrology";
import { RootState } from "@src/store";
import axios from "axios";
import moment from "moment";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { LineChart } from "./charts/line";
import { remoteTemperatureDeveui } from "../index";
import { AxisDomain } from "recharts/types/util/types";
import { useTranslation } from "react-i18next";

export default function  Generic({ eng, scaleValues}: Props) {
  const {t, i18n} = useTranslation("metrology");
  const [params] = useSearchParams();
  const mode = useSelector((state: RootState) => state.metrology.mode);
  const start = useSelector((state: RootState) => state.metrology.start);
  const end = useSelector((state: RootState) => state.metrology.end);
  const format = useSelector(
    (state: RootState) => state.metrology.displayFormat[eng]
  );
  const selectedSensors = useSelector(
    (state: RootState) => state.metrology.selectedSensors
  );

  const remoteTemperatureDisplay = useSelector(
    (state: RootState) => state.metrology.remoteTemperatureDisplay
  );
  const dateIsChanging = useSelector(
    (state: RootState) => state.metrology.dateIsChanging
  );
  const header = useSelector(
    (state: RootState) => state.metrology.header
  );
  const energyDataBySensor = useSelector(
    (state: RootState) => state.metrology.energyDataBySensor
  );
  const sensors = useSelector(
    (state: RootState) => state.metrology.sensors
  );
  const dispatch = useDispatch();

  const getSensorsNames = (listOfSensors: ListOfDetailledSensors): ListOfSensorsNames => {
    return Object.values(listOfSensors || {})
      .flat(1)
      .reduce(
        (prev, sensor) =>
          Object.assign(prev, { [sensor.name]: sensor.color }),
        {}
      );
  };

  const flatEnergyDataBySensor = () => {
    const res: any = {};
    for (const type_eng in energyDataBySensor) {
      const sensorsData = (energyDataBySensor as any)[type_eng];
      for (const sensorName in sensorsData) {
        res[sensorName] = type_eng;
      }
    }
    return res;
  };

  const getSensorsToFetch = (
    namesOfAllSensors: ListOfSensorsNames,
    namesOfSelectedSensors: ListOfSensorsNames,
  ): ListOfSensorsNames => {
    const sensorsToFetch: ListOfSensorsNames = {};
    const flatEnergy = flatEnergyDataBySensor(); 
    for (const [sensorName, sensorColor] of Object.entries(namesOfAllSensors)) {
      if (namesOfSelectedSensors[sensorName]) {
        if (flatEnergy[sensorName]) {
          // just activate this sensor because it already exist in energyDataBySensor
          dispatch(switchSensorDisplay({sensorLabel: sensorName, bool: true, eng: flatEnergy[sensorName], mode: mode}));
        }
        else {
          // register as a sensor to fetch in backend api
          sensorsToFetch[sensorName] = sensorColor;
        }
      }
      else {
        if (energyDataBySensor[eng][sensorName]) {
          // deactivate the display of this sensor because it is not selected anymore
          dispatch(switchSensorDisplay({sensorLabel: sensorName, bool: false, eng: eng, mode: mode}));
        }
      }
    }
    return sensorsToFetch;
  };

  const namesOfAllSensors = getSensorsNames(sensors);
  const previousDelta = useRef<number>(end - start);
  const previousFormat = useRef<string>(format);

  // useEffect to handle Sensors Data
  useEffect(() => {
    if (!sensors) {return;}

    let sensorsToFetch: ListOfSensorsNames = {};

    const namesOfSelectedSensors = getSensorsNames(selectedSensors);

    if ((end - start != previousDelta.current) || (format != previousFormat.current)) {
      dispatch(setDateIsChanging(true));
      sensorsToFetch = namesOfSelectedSensors;
      dispatch(resetDataBySensor({eng: eng, mode: mode}));
    }
    else {
      sensorsToFetch = getSensorsToFetch(namesOfAllSensors, namesOfSelectedSensors);
    }
    
    previousDelta.current = end - start;
    previousFormat.current = format;

    const deveui_param = new URLSearchParams();
    deveui_param.set("deveui", JSON.stringify(sensorsToFetch));

    if (mode === null) {return;}
    if (Object.keys(sensorsToFetch).length) {
      dispatch(setDataIsLoadingByType({ bool: true, eng: eng }));
      axios
        .get(
          `/api/v1/${mode}/series?building=${params.get(
            "building"
          )}&${deveui_param}&startDate="${moment(start).format("YYYY-MM-DD HH:mm:ss")
          }"&endDate="${moment(end).format("YYYY-MM-DD HH:mm:ss")
          }"&period=${format}&mode=${mode}&eng=${eng}`
        )
        .then((res) => {
          dispatch(updateDataBySensor({data: res.data, eng: eng, mode: mode}));
        })
        .finally(() => {
          dispatch(setDataIsLoadingByType({ bool: false, eng: eng }));
          dispatch(setDateIsChanging(false));
        });
    }
  }, [selectedSensors, mode, format, start, end]);

  // useEffect to handle Remote Temperature
  useEffect(() => {
    if (!["capt", "elec"].includes(eng)) {return;}
    if (dateIsChanging) {return;}
    if (mode === null) {return;}

    if (remoteTemperatureDisplay) {
      if (!energyDataBySensor[eng][remoteTemperatureDeveui]) {
        dispatch(setDataIsLoadingByType({ bool: true, eng: eng }));
        axios
          .get(
            `/api/v1/${mode}/meteo/temperature?building=${params.get(
              "building"
            )}&startDate=${moment(start).format("YYYY-MM-DD HH:mm:ss")
            }&endDate=${moment(end).format("YYYY-MM-DD HH:mm:ss")
            }&period=${format}`
          )
          .then((res) => {
            dispatch(updateDataBySensor({data: res.data, eng: eng, mode: mode}));
          })
          .finally(() => {dispatch(setDataIsLoadingByType({ bool: false, eng: eng }));});
      }
      else {

        dispatch(switchSensorDisplay({sensorLabel: remoteTemperatureDeveui, bool: true, eng: eng, mode: mode}));
      }
    }
    else if (energyDataBySensor[eng][remoteTemperatureDeveui]) {
      dispatch(switchSensorDisplay({sensorLabel: remoteTemperatureDeveui, bool: false, eng: eng, mode: mode}));
    }
  }, [remoteTemperatureDisplay, dateIsChanging]);

  const _data: any = Object.values(energyDataBySensor[eng]).filter(sensor => sensor.display).map(sensorToDisplay => sensorToDisplay.sensor) ;
  const data: any = _data.length? {[eng]: _data} : {};

  useEffect(() => {
    if (eng == "capt") return ;
    const sensorNames = JSON.stringify(
      Object.values(selectedSensors || {}).flat(1).reduce(
        (prev, sensor) => Object.assign(prev, { [sensor.name]: sensor.color }),
        {}
      ));

    const param = new URLSearchParams();
    param.set("deveui", sensorNames);
    
    dispatch(setHeaderIsLoadingByType({ bool: true, eng: eng }));
    if (mode === null) {
      return;
    }
    axios
      .get(
        `/api/v1/${mode}/header/series?building=${params.get(
          "building"
        )}&${param}&startDate="${moment(start).format("YYYY-MM-DD HH:mm:ss")
        }"&endDate="${moment(end).format("YYYY-MM-DD HH:mm:ss")
        }"&period=${format}&mode=${mode}&eng=${eng}`
      )
      .then((res) => {
        dispatch(setHeaderByType({header: res.data, eng}));
      }).finally(() => {
        dispatch(setHeaderIsLoadingByType({bool: false, eng: eng}));
      });
  }, [start, end, selectedSensors]);


  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  const translate = {
    elec: t("Electricity power (kW)"),
    hot: t("Heating power (kW)"),
    cold: t("Cooling power (kW)"),
    capt: t("Temperature (°C)"),
    water: t("Water consumption") + " (m\u00b3)",
    gas: t("Gas consumption") + " (m\u00b3)",
    thermostat: t("Thermostat status [I/O]"),
  };

  const translateYAxisBarChartTitle = {
    elec: t("Electricity consumption (kWh) "),
    hot: t("Heating consumption (kWh)"),
    cold: t("Cooling consumption (kWh)"),
    capt: t("Temperature (°C)"),
    thermostat: t("Thermostat (ON/OFF)"),
    water: t("Water consumption") + " (m\u00b3)",
    gas: t("Gas consumption") + " (m\u00b3)",
  };
  
  const translateYBis = {
    cold: t("Volume") + " (m\u00b3)",
    capt: t("Co2 concentration (ppm)"),
  };

  const translateYAxisBarChartTitleBis = {
    cold: t("Volume") + " (m\u00b3)",
    capt: t("Co2 concentration (ppm)"),
  };

  const curveLabels: {[deveui: string]: string} = Object.values(selectedSensors || {})
    .flat(1)
    .reduce(
      (prev, sensor) =>
        Object.assign(prev, {
          [sensor.name]: sensor.gui_informations?.label || sensor.name,
        }),
      {}
    );
  
  curveLabels["deveui_meteo_temperature"] = gettext("Outdoor temperature");

  return (
    <>
      <LineChart
        eng={eng}
        lookupLabels={curveLabels}
        header={header}
        withPeriod
        data={data[eng] as any}
        XKey="date"
        YKey={eng === "capt" ? "_value" : "value"}
        chartId={"line-" + eng}
        yAxisUnit={translate[eng]}
        yAxisBarChartTitle={translateYAxisBarChartTitle[eng]}
        yAxisUnitBis={translateYBis[eng as keyof typeof translateYBis]}
        yAxisBarChartTitleBis={translateYAxisBarChartTitleBis[eng as keyof typeof translateYAxisBarChartTitleBis]}
        domain={scaleValues}
      />
    </>
  );
}

interface Props {
  eng: "hot" | "cold" | "elec" | "capt" | "water" | "gas" | "thermostat";
  scaleValues?: any[];
}
