export default {
  "Building": "Bâtiment",
  "Buildings": "Bâtiments",
  "All regions": "Toutes les régions",
  "Select": "Selectionner",
  "Summer": "Été",
  "Winter": "Hiver",
  "List": "Liste",
  "Table": "Tableau",
  "Analysis": "Analyse",
  "All": "Tous",
  "Loading": "Chargement",
  "Error loading dashboard": "Erreur lors du chargement du tableau de bord",
  "Sensors filters": "Filtres des capteurs",
  "Building / Batch": "Bâtiments / Lot",
  "Floor": "Étage",
  "Room Type": "Type de Salle",
  "Tenant": "Locataire",
  "Sensors by page": "Capteurs par page",
  "Number of sensors": "Nombre de capteurs",
  "Temperature / Air Quality": "Température / Qualité de l'air",
  "Region": "Région",
  "Sensor": "Capteur",
  "Last reading": "Dernier relevé",
  "Average occupancy": "Moyenne en occupation",
  "Average vacancy": "Moyenne en inoccupation",
  "Curve": "Courbe",
  "Sensor state": "État capteur",
  "No data available": "Aucune donnée disponible",
  "Previous": "Précédent",
  "Next": "Suivant",
  "See curve": "Voir Courbe",
  "Default": "Défaut",
  "Temperature": "Température",
  "The floors and/or areas of the equipment are defined incorrectly.": "Les étages et/ou les zones des équipements sont mal définies.",
  "All types": "Toutes les types",
  "Data Type": "Type de donnée",
  "All rooms": "Toutes les salles",
  "All zones": "Toutes les zones",
  "All floors": "Tous les étages",
  "All tenants": "Tous les locataires",
  "Select all": "Selectionner tout",
  "Unselect all": "Déselectionner tout",
  "Sensor selection": "Séléction des capteurs",
  "Error during data recovery": "Erreur lors du chargement des données",
  "Temperature distribution": "Répartition des températures",
  "from": "du",
  "to": "au",
  "Average daily temperatures": "Moyenne des températures par jour",
  "Average temperatures for the entire period": "Moyenne des températures pour toute la période",
  "greater than 72h":"supérieur à 72h",
  "Display date of the last message": "Afficher la date du dernier message",
  "Air quality": "Qualité d'air",
  "Interval": "Intervalle",
  "Measures distribution": "Répartition des mesures",
  "Average values per day": "Valeurs moyennes par jour",
  "Indicators for the entire period": "Indicateurs pour toute la période",
  "Average": "Moyenne",
  "Occupied": "Occupation",
  "Unoccupied": "Inoccupation",
  "Weekend": "Weekend",
  "These are the default thresholds by buildings, if no changes have been made to the thresholds on the metrology page": "Ce sont les seuils par défaut pour chaque bâtiment, si aucun changement n'a été effectué dans les seuils de la page metrologie"
};
