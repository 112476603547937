import { List, ListItem, Typography } from "@mui/material";
import {useTranslation} from "react-i18next";

function PasswordInfo() {
  const { t } = useTranslation();

  return (
    <div className="card-body text-left">
      <Typography variant="body2" color="text.highlightPrimary">
        {t("Requirements for the password:")}
      </Typography>
      <List dense>
        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Typography color="text.highlightPrimary">
            <small>{t("must contain at least 9 caracters")}</small>
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Typography color="text.highlightPrimary" sx={{ paddingTop: 0, paddingBottom: 0 }}>
            <small>{t("must contain at least 1 digit")}</small>
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Typography color="text.highlightPrimary">
            <small>{t("must contain at least 1 uppercase letter")}</small>
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Typography color="text.highlightPrimary">
            <small>{t("must contain at least 1 lowercase")}</small>
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Typography color="text.highlightPrimary">
            <small>{t("must contain at least 1 special character")}</small>
          </Typography>
        </ListItem>
      </List>
    </div>
  );
}

export default PasswordInfo;