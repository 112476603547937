export const accentaClient = {
  palette: {
    primary: {
      main: "#03002E",
      dark: "#02001a",
      light: "#DEEBFF",
    },
    secondary: {
      main: "#EE335E",
    },
  },
};