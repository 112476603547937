import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import "bootstrap/dist/css/bootstrap.min.css";
import "dropify/src/sass/dropify.scss";
import "./plugins/feather/feather.css";
import "./scss/style.scss";
import "./scss/lib/valex/sidemenu.scss";
import "./scss/lib/valex/skin-modes.scss";
// import "./scss/_variables.scss";

import "./css/animate.css";
import "./css/select2.css";
import "./css/sidebar.css";

import "./components/custom";
import "./components/left-menu";
import "./components/notify";
import "./components/sidebar";

import {initSideMenu} from "./components/sidemenu";

export const accentaClient = {
  primary: {
    main: "#03002E",
    dark: "#02001a",
    light: "#7066ff",
  },
  secondary: {
    main: "#EE335E",
  },
};

export const esmeClient = {
  primary: {
    main: "#672575",
    dark: "#561f61",
    light: "#cd8bda",
  },
  secondary: {
    main: "#24AD8D",
  },
  text: {
    highlightPrimary: "#672575",
    highlightSecondary: "#24AD8D"
  },
};

let client;

switch (process.env.REACT_APP_CLIENT) {
case "esme":
  client = esmeClient;
  break;
case "accenta":
default:
  client = accentaClient;
}

const root = document.documentElement;
root.style.setProperty("--primary", client.primary.main);
root.style.setProperty("--primary-dark", client.primary.dark);
root.style.setProperty("--primary-light", client.primary.light);
root.style.setProperty("--secondary", client.secondary.main);


$(function () {
  import("dropify").then(() => $(".dropify").dropify());


  Vue.component("treeselect", VueTreeselect.Treeselect);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const parc = params.get("parc");
  const building = params.get("building");

  initSideMenu();
  new Vue({
    el: "#app",
    autoSelectAncestors: true,
    data: {
      // define the default value
      value: parc && `parc=${parc}${building ? `&building=${building}` : ""}`,
      // define options
      options: null,
    },
    mounted: function () {
      $.ajax({
        url: "/tree_select",
        method: "GET",
        success: (data) => {
          this.options = data;
        },
        error: (error) => {
          console.log(error);
        }
      });
    },
    methods: {
      onInput(value) {
        if (value) {
          const newParams = new URLSearchParams(value);

          params.set("parc", newParams.get("parc"));

          if (newParams.has("building")) {
            params.set("building", newParams.get("building"));
          } else {
            params.delete("building");
          }

          return window.location.search = params;
        }
        return value;
      }
    }
  });
});
