import HeaderResetPassword from "@src/auth/react/sections/header/HeaderResetPassword";
import ResetPasswordForm from "@src/auth/react/sections/form/ResetPasswordForm";
import ResetPasswordFooter from "@src/auth/react/sections/footer/ResetPasswordFooter";
import Layout from "@src/auth/react/sections/Layout";
import "../styles/shared.scss";
import "../styles/reset-password.scss";

export function ResetPasswordPage() {
  return (
    <Layout>
      <div className="main-card-signin d-md-flex">
        <div className="wd-100p">
          <div className="main-signin-header">
            <div>
              <HeaderResetPassword/>
              <ResetPasswordForm/>
            </div>
          </div>
          <ResetPasswordFooter/>
        </div>
      </div>
    </Layout>
  );
}