import { Spinner } from "@src/common/components/is-loading";
import { RootState } from "@src/store";
import {CSSProperties, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { CSSProp } from "styled-components";
import { setOpenCharts as storeOpenCharts } from "@src/store/reducers/metrology";
import {useSearchParams} from "react-router-dom";
import { useTranslation } from "react-i18next";


export function Card(props: Props) {
  const {t} = useTranslation("metrology");
  const dataIsLoading = useSelector(
    (state: RootState) => state.metrology.dataIsLoading
  );
  const openCharts = useSelector(
    (state: RootState) => state.metrology.openCharts
  );
  const chartType =  (props.eng as keyof typeof openCharts);
  const chartIsOpen = openCharts[chartType];
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const updateURL = (params: URLSearchParams) => {
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    if (window.history.pushState) {
      window.history.pushState({path: newUrl}, "", newUrl);
    } else {
      window.location.href = newUrl;
    }
  };

  useEffect(() => {
    const openChartParam = searchParams.get(`chart-${chartType}`);
    if (openChartParam === null) {
      dispatch(storeOpenCharts({type: props.eng, value: chartIsOpen}));
      return;
    }
    const isOpen = !!(+openChartParam);
    if (chartIsOpen !== isOpen) {
      dispatch(storeOpenCharts({type: props.eng, value: isOpen}));
    }
  }, []);

  return (
    <div className="panel panel-default mb-4" id={"accordion-" + props.id}>
      <div className="panel-heading1">
        <h4 className="panel-title1" style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
          <a className={"accordion-toggle mb-0 " + (chartIsOpen ? "": "collapsed")}
            style={{fontSize: "16px", fontWeight: 500}}
            data-toggle="collapse"
            data-parent={"#accordion-" + props.id}
            onClick={(event) => {
              const searchParams = new URLSearchParams(window.location.search);
              const newChartIsOpen =  !chartIsOpen;
              const param = `chart-${chartType}`;
              const value = (!chartIsOpen) ? "1" : "0";
              searchParams.set(param, value);
              updateURL(searchParams);
              dispatch(storeOpenCharts({type: props.eng, value: newChartIsOpen}));
            }}
            aria-expanded={chartIsOpen}>
            {t(props.title)} {dataIsLoading[props.eng] && <Spinner height="22px" width="22px"/>}</a>
        </h4>
      </div>
      <div id={"collapse-" + props.id}
        className={"panel-collapse collapse " + (chartIsOpen ? "show": "hide" )}
        role="tabpanel"
        css="">
        <div className="panel-body" css="padding: 0">
          <div className="card mb-0" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0, ...props.cardStyle as any}}>
            <div className="card-body" style={props.cardBodyStyle}>
              {
                props.children
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface Props {
  id: string;
  title: string;
  children: any;
  headerChart?: any;
  cardBodyStyle?: CSSProperties;
  cardStyle?: CSSProp;
  collapsed?: boolean;
  eng?: string;
}