import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";


interface Props {
    text?: string;
    awesomeClassIcon?: string;
    klass?: string; 
    onExportHandler?: () => void;
    onClickHandler?: () => void;
}

function ButtonExport({onExportHandler, text, awesomeClassIcon="fa-solid fa-file-arrow-down"}: Props) {
  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);
  
  return (
    <Button 
      variant="contained"
      color="primary"
      className="d-flex align-items-center"
      onClick={onExportHandler}>
      <i className={`${awesomeClassIcon} mr-2`}></i>
      <span style={{top: "2px", position: "relative"}}>
        {text ?? t("Export")}
      </span> 
    </Button>
  );
}

export function PlainButton({onClickHandler, text="", klass=""}: Props) {
  return (
    <Button 
      variant="contained"
      color="primary"
      className={`d-flex align-items-center ${klass}`}
      onClick={onClickHandler}>
      {text}
    </Button>
  );
}
export default ButtonExport;

