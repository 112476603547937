import { Fragment } from "react";
import { Card } from "./collapse-card";
import Generic from "./generic";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";

export default function Energy() {
  const sensors = useSelector(
    (state: RootState) => state.metrology.sensors
  );
  const energySources = Object.values(sensors || {}).flat(1).map(sensor => sensor.energy_source);

  const ELEC_HEADER_ITEM_HEIGHT = 470;
  const PERIOD_MENU_HEIGHT = 100;
  const charts = [
    {
      eng: "elec",
      title: "Electricity",
      id: "line-elec",
      height: 400 + PERIOD_MENU_HEIGHT + ELEC_HEADER_ITEM_HEIGHT,
      children: () => energySources.includes("elec") ? <Generic eng="elec" /> : null,
    },
    {
      eng: "hot",
      title: "Heating Network",
      id: "line-hot",
      height: 365 + PERIOD_MENU_HEIGHT + ELEC_HEADER_ITEM_HEIGHT,
      children: () => energySources.includes("hot") ? <Generic eng="hot" /> : null,
    },
    {
      eng: "cold",
      title: "Cooling Network",
      id: "line-cold",
      height: 365 + PERIOD_MENU_HEIGHT + ELEC_HEADER_ITEM_HEIGHT,
      children: () => energySources.includes("cold") ? <Generic eng="cold" /> : null,
    },
    {
      eng: "capt",
      title: "HVAC Systems Sensors",
      id: "line-temp-co2",
      height: 400 + PERIOD_MENU_HEIGHT,
      children: () => energySources.includes("capt") ? <Generic eng="capt" /> : null,
    },
    {
      eng: "water",
      title: "Water Consumption",
      id: "line-water",
      height: 400 + PERIOD_MENU_HEIGHT,
      children: () => energySources.includes("water") ? <Generic eng="water" /> : null,
    },
    {
      eng: "gas",
      title: "Gas Consumption",
      id: "line-gas",
      height: 400 + PERIOD_MENU_HEIGHT,
      children: () => energySources.includes("gas") ? <Generic eng="gas" /> : null,
    },
    {
      eng: "thermostat",
      title: "Thermostat",
      id: "line-thermostat",
      height: 400 + PERIOD_MENU_HEIGHT,
      children: () => energySources.includes("thermostat") ? <Generic eng="thermostat" scaleValues={[0, 1]}/> : null,
    },
  ];

  return (
    <Fragment>
      {charts?.map(({ children, ...def }) => {
        const render = children();
        if (render) {
          return (
            <Card
              cardBodyStyle={{ height: def.height }}
              key={def.id}
              {...def}
            >
              {children()}
            </Card>
          );
        }
      })}
    </Fragment>
  );
}
