import {useTotalIndicators} from "@src/dashboards/parks/react/sections/indicators/use-total-indicators";
import TotalIndicator from "@src/dashboards/parks/react/sections/indicators/TotalIndicator";


export function TotalIndicators() {
  const presenter = useTotalIndicators();
  const { t } = presenter;

  return (
    <div className="col">
      <div className="row row-sm">
        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <TotalIndicator title="Total Consumption" unit="MWh" value={presenter.totalConsumption} color="bg-warning-gradient"/>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <TotalIndicator title="Expenses" unit="€ HT" value={presenter.totalExpenses} color="bg-danger-gradient"/>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <TotalIndicator title="Emissions" unit="tCO₂eq" value={presenter.totalEmissions} color="bg-secondary-gradient"/>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="card overflow-hidden sales-card bg-success-gradient">
            <div className="ps-3 pt-3 pe-3 pb-2 pt-0 total-headers">
              <div>
                <h6 className="mb-3 tx-16 text-white">
                  {t("Savings", {ns: "dashboardPark"})}
                </h6>
              </div>
              <div className="pb-0 mt-0">
                <div className="d-flex">
                  <div id="parc-total-economy">
                    <h6 className="no-data-btn text-white tx-22">{t("No data available")}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
