import {useTranslation} from "react-i18next";
import {useForgotPasswordForm} from "@src/auth/react/sections/form/use-forgot-password";
import Loading from "@src/common/components/is-loading";
import { Button } from "@mui/material";

type Props = {
    csrfToken: string;
}

export function ForgotPasswordForm({csrfToken}: Props) {
  const { t } = useTranslation();
  const presenter = useForgotPasswordForm();

  return (
    <form onSubmit={presenter.onSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken}/>
        <input
          {...presenter.register("email", presenter.emailOptions)}
          className="form-control"
          placeholder={t("Enter your email")}
          name="email"
          id="email"
          type="email"
        />
      </div>
      <Button variant="contained" color="primary" type="submit" sx={{width: "100%"}} disabled={presenter.isSubmittable}>
        {t("Send")}
      </Button>
      { presenter.requestStatus == "pending" && <Loading />}
    </form>
  );
}